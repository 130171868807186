import React from "react";
import { Container, Box, Flex, NavLink } from "@theme-ui/components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text } from "@theme-ui/components";
import NavBar from "../components/NavBar";
import { graphql, navigate } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "@theme-ui/components";
import Seo from "../components/seo";
import "./../components/style.css";
import { login, logout, isAuthenticated, getProfile } from "../utils/auth";
import { loadStripe } from "@stripe/stripe-js";

const Checkout = () => {
	let stripePromise;

	const getStripe = () => {
		if (!stripePromise) {
			stripePromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY_LIVE}`);
		}
		return stripePromise;
	};

	//Stripe checkout option
	const redirectToCheckout = async (priceId, successUrl) => {
		const stripe = await getStripe();
		const { error } = await stripe.redirectToCheckout({
			// mode: "payment",
			mode: "subscription",
			lineItems: [
				{
					price: `${priceId}`,
					quantity: 1,
				},
			],
			successUrl: `${successUrl}`,
			cancelUrl: `${process.env.STRIPE_CANCEL_URL}`,
		});
		if (error) {
			console.warn("Error:", error);
		}
	};

	const monthlyPayment = () => {
		const priceId = process.env.STRIPE_ITEM_SUBSCRIPTION_LIVE_1_MONTH;
		const successUrl = process.env.STRIPE_SUCCESS_URL_MONTHLY;
		redirectToCheckout(priceId, successUrl);
	};
	const yearlyPayment = () => {
		const priceId = process.env.STRIPE_ITEM_SUBSCRIPTION_LIVE_1_YEAR;
		const successUrl = process.env.STRIPE_SUCCESS_URL_YEARLY;
		redirectToCheckout(priceId, successUrl);
	};
	return (
		<div>
			<Seo />
			<NavBar />
			<div className="checkout-container">
				<Box
					sx={{
						width: 550,
						padding: 1,
						borderRadius: 2,
						border: "1px solid",
						borderColor: "primary",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "row",
						}}
					>
						<Card
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<h1>Monthly</h1>
							<p>$39 / month</p>
						</Card>
						<Card
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<h1>Yearly</h1>
							<Text
								as="p"
								sx={{
									mb: "-5px",
								}}
							>
								$360 / year
							</Text>
							<hr />
							<Text
								as="h1"
								sx={{
									mb: "-20px",
								}}
							>
								23%
							</Text>
							<Text
								as="h1"
								sx={{
									mb: "-10px",
								}}
							>
								SAVINGS
							</Text>
						</Card>
					</Box>

					<Box
						sx={{ display: "flex", justifyContent: "center", gap: 90, mb: 20 }}
					>
						<Button onClick={monthlyPayment}>Buy now</Button>
						<Button onClick={yearlyPayment}>Buy now</Button>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default Checkout;
